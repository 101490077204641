const ex = `<div class="dropdown primary">
    <span>Primary</span><i class="dropdown-caret"></i>
    <div class="dropdown-hover"></div>
    <div class="dropdown-items">
        <p>Item 1</p>
        <p>Item 2</p>
        <p>Item 3</p>
    </div>
</div>`;

export default ex;
