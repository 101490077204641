export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_SITE_THEME = "SET_SITE_THEME";
export const RESET_SITE_THEME = "RESET_SITE_THEME";
export const SET_USER_THEME = "SET_USER_THEME";
export const RESET_USER_THEME = "RESET_USER_THEME";
export const SET_THEMES = "SET_THEMES";
export const SET_USER_THEMES = "SET_USER_THEMES";
export const SET_THEME_ALERT = "SET_THEME_ALERT";
export const RESET_THEME_ALERT = "RESET_THEME_ALERT";
export const SET_USER_FAVORITES = "SET_USER_FAVORITES";
